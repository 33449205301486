/* root variables */
:root {
    --theme: 'light';
    --text: rgba(175,175,175,1);
    --soft_border: rgba(175,175,175,1);
    --textfield: rgba(245,245,245,1);
    --sat: env(safe-area-inset-top, 0px);
    --sar: env(safe-area-inset-right, 0px);
    --sab: env(safe-area-inset-bottom, 0px);
    --sal: env(safe-area-inset-left, 0px);
    --PhoneInput-color--focus: #4A90E2 !important;
}

/* default */
html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
html ::-webkit-scrollbar {
    width: 0px;
    background: rgba(0,0,0,0);
}
body {
    transition: background-color 1000ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
body.light {
    background-color: #F0F1F5 !important;
}
body.dark {
    background-color: rgba(25,25,25,1) !important;
}
@media (prefers-color-scheme: dark) {
    body {
        background-color: rgba(25,25,25,1);
        color: white;
    }
}

/* containers */
.panel-content {
    transition: border-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06), background-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06), box-shadow 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.root-container {
    transition: background-color 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.sortable-container {
    z-index: 7500
}

/* flow nodes */
.react-flow__controls {
    border-radius: 5px !important;
    overflow: hidden
}
.react-flow__controls-button {
    fill: var(--soft_border) !important;
    background: rgba(250,250,250,1) !important;
    border-bottom: none !important;
}
.react-flow__controls-button:not(.react-flow__controls-interactive) {
    border-bottom: 1px solid #eee !important;
}

.react-flow__node-default {
    border-color: var(--soft_border) !important;
    background-color: rgba(250,250,250,1) !important;
}
@media (prefers-color-scheme: dark) {
    .react-flow__controls-button {
        fill: white !important;
        background: rgba(65,65,65,1) !important;
    }
    .react-flow__controls-button:not(.react-flow__controls-interactive) {
        border-bottom: 1px solid rgba(85,85,85,1) !important;
    }
    .react-flow__node-default {
        background-color: rgba(45,45,45,1) !important;
    }
}

/* omnishield */
.omnishield-guage-needle {
    transition: transform 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

/* sidebar elements */
.dropdown-menu {
    z-index: 1000;
    min-width: 10rem;
    padding: 10px 0;
    margin: 0 0 0;
    font-size: 1rem;
    border-radius: 0.375rem;
}
.dropdown-menu.light {
    color: rgba(85,85,85,1);
}
.dropdown-menu.dark {
    color: white;
}
.dropdown-menu-small {
    font-size: 0.813rem;
}
.dropdown-menu-small .dropdown-item {
    font-size: 0.813rem;
}
.dropup .dropdown-menu {
    margin-bottom: 0;
}
.dropright .dropdown-menu {
    margin-left: 0;
}
.dropleft .dropdown-menu {
    margin-right: 0;
}
.dropdown-divider {
    height: 0;
    margin: 0.75rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(65,65,65,1);
}
.dropdown-item {
    padding: 0.5rem 1.25rem;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 0.9375rem;
    background-color: rgba(0,0,0,0);
}
.dropdown-item.light:hover, .dropdown-item.light:focus {
    color: #FFFFFF;
    background-color: rgba(245,245,245,1);
}
.dropdown-item.dark:hover, .dropdown-item.dark:focus {
    color: #FFFFFF;
    background-color: rgba(85,85,85,1);
}
.dropdown-item.light.active, .dropdown-item.light:active {
    color: #fff;
    background-color: rgba(245,245,245,1);
}
.dropdown-item.dark.active, .dropdown-item.dark:active {
    color: #fff;
    background-color: rgba(85,85,85,1);
}
.dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
}
.dropdown-item.disabled:hover, .dropdown-item:disabled:hover {
    background: none;
    cursor: not-allowed;
}

/* tables */
.flexible-table-column {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 0px
}
.non-flexible-table-column {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 0px
}

/* maps */
.mapboxgl-canvas {
    border-radius: 10px !important;
    overflow: hidden !important;
}

/* custom backgrounds */
.pinstripes {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,0.25),
        rgba(255,255,255,0.25) 10px,
        rgba(255,255,255,0.1) 10px,
        rgba(255,255,255,0.1) 20px
    );
}
.pinstripes.dark {
    background: repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,0.12),
        rgba(255,255,255,0.12) 10px,
        rgba(255,255,255,0.075) 10px,
        rgba(255,255,255,0.075) 20px
    );
}

/* scheduler cursor modifications */
.cursor-ewresize {
    opacity: 0.65 !important;
    cursor: ew-resize !important;
}
.cursor-ewresize:hover {
    opacity: 0.65 !important;
    cursor: ew-resize !important;
}

/* tag input field */
.react-tag-input {
    border-radius: 8px !important;
    border: 1px solid rgba(175,175,175,0.25) !important;
    background-color: rgba(245,245,245,1) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.react-tag-input:focus-within {
    background-color: white !important;
    box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}
.react-tag-input__input {
    font-size: 12px !important;
    height: 30px
}

/* wysiwyg editor */
.rdw-editor-toolbar {
    padding: 12px 8px 12px 8px !important;
    border-radius: 10px !important;
    border-color: var(--soft_border) !important;
}
.rdw-option-wrapper {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text) !important;
    min-width: 35px !important;
    height: 30px !important;
    padding: 8px !important;
    border-color: var(--soft_border) !important;
    border-radius: 8px !important;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.rdw-option-wrapper:hover {
    box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important
}
.rdw-dropdown-wrapper  {
    height: 30px !important;
    padding-left: 8px !important;
    border-radius: 8px !important;
    border-color: var(--soft_border) !important;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.rdw-dropdown-wrapper:hover  {
    box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important
}
.rdw-dropdown-selectedtext {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text) !important;
}
.rdw-dropdown-selectedtext:hover {
    text-decoration: none !important;
}
.rdw-dropdown-optionwrapper {
    margin-top: 6px !important
}
.rdw-option-active {
    border-color: var(--text) !important;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.1) inset !important
}
.rdw-colorpicker-option {
    width: 30px !important;
    height: 30px !important;
    border-radius: 17.5px !important;
    padding: 0px !important;
    margin: 0px !important;
    box-shadow: none !important
}
.rdw-colorpicker-option:hover {
    box-shadow: none !important;
}
.rdw-colorpicker-cube {
    width: 30px !important;
    height: 30px !important;
    border-radius: 17.5px !important;
}
.rdw-colorpicker-cube:hover {
    opacity: 0.75 !important
}
.rdw-colorpicker-modal-style-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text) !important;
}
.rdw-colorpicker-modal-style-label-active {
    border-bottom: none !important;
    color: #4A90E2 !important;
}
.rdw-colorpicker-modal {
    margin: 0 !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    border: 1px solid var(--soft_border) !important;
}
.rdw-emoji-modal {
    margin: 0 !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    border: 1px solid var(--soft_border) !important;
}
.rdw-dropdown-optionwrapper {
    padding: 8px !important;
    margin-top: 6px !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    border: 1px solid var(--soft_border) !important;
}
.rdw-dropdown-selectedtext {
    width: 50px !important
}
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose {
    margin-right: 4px
}
.rdw-dropdownoption-default.rdw-fontsize-option {
    border-radius: 6px !important
}
.rdw-editor-toolbar {
    background-color: rgb(252,252,252) !important;
}
.rt-editor-toolbar.light {
    background-color: rgb(252,252,252) !important;
}
.rt-editor-toolbar.dark {
    background-color: rgba(150,150,150,0.15) !important;
}
.rt-button.light {
    background-color: rgb(252,252,252) !important;
}
.rt-button.dark {
    background-color: rgba(75,75,75,1) !important;
}
.rt-modal.light {
    background-color: rgb(252,252,252) !important;
}
.rt-modal.dark {
    background-color: rgba(75,75,75,1) !important;
}
@media (prefers-color-scheme: dark) {
    .rdw-option-wrapper {
        background-color: rgba(75,75,75,1) !important;
    }
    .rdw-dropdown-carettoopen {
        border-top-color: white !important;
    }
    .rdw-dropdown-carettoclose {
        border-bottom-color: white !important;
    }
}

/* phone number input field */
.PhoneInputCountrySelectArrow {
    margin-bottom: 2px !important;
    margin-left: 6px !important;
    width: 5px !important;
    height: 5px !important;
}
.PhoneInputCountryIcon {
    border-width: 0px !important;
    width: 20px !important;
    height: 13px !important;
    object-fit: cover !important;
    overflow: hidden !important;
    border-radius: 2px !important;
}
.PhoneInputCountryIcon--border {
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none !important;
}
.PhoneInputInput {
    background: rgba(0,0,0,0);
    border-width: 0px;
    padding: 0.5275rem 0.75rem;
    color: var(--text) !important;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 500;
    will-change: border-color, box-shadow;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.PhoneInputInput:active {
    background: rgba(0,0,0,0) !important;
    border-width: 0px !important;
    border: none !important;
    outline: none !important;
}
.PhoneInputInput:focus {
    background: rgba(0,0,0,0) !important;
    border-width: 0px !important;
    border: none !important;
    outline: none !important;
}
.PhoneInputInput.light:-webkit-autofill, .PhoneInputInput.light:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
    border-top: 1px solid rgba(175,175,175,0.25) !important;
    border-bottom: 1px solid rgba(175,175,175,0.25) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: #4A4A4A;
}
.PhoneInputInput.light:-webkit-autofill, .PhoneInputInput.light:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px rgba(245,245,245,1) inset;
    border-top: 1px solid rgba(175,175,175,0.25) !important;
    border-bottom: 1px solid rgba(175,175,175,0.25) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: #4A4A4A;
}
.PhoneInputInput.dark:-webkit-autofill, .PhoneInputInput.dark:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px rgba(100,100,100,1) inset;
    border-top: 1px solid rgba(125,125,125,1) !important;
    border-bottom: 1px solid rgba(125,125,125,1) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: white;
}
.PhoneInputInput.dark:-webkit-autofill, .PhoneInputInput.dark:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px rgba(85,85,85,1) inset;
    border-top: 1px solid rgba(110,110,110,1) !important;
    border-bottom: 1px solid rgba(110,110,110,1) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: white;
}

/* custom patterns */
.anchors {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23bababa' fill-opacity='0.2' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");
}
.bubbles {
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.charlie_brown {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.circles {
    background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.diamonds {
    background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.dots {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
}
.leaves {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23bababa' fill-opacity='0.2' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
.stars {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
}
.stripes {
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23bababa' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}
.triangles {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23bababa' fill-opacity='0.2'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* page loader animations */
.circle-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -28px 0 0 -28px;
    width: 150px;
    height: 150px;
    border-radius: 100%;
}
.circle-loader:after {
    content: '';
    background: trasparent;
    width: 110%;
    height: 110%;
    position: absolute;
    border-radius: 100%;
    top: -15%;
    left: -15%;
    opacity: 1;
    box-shadow: rgba(255, 255, 255, 0.75) -4px -5px 3px -3px;
    animation: rotate 1.5s infinite linear;
}
.rotate-animation {
    animation: rotate 1.5s infinite linear;
}
@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

/* view entry elements */
.view-entry.light:hover {
    cursor: pointer;
    background-color: rgba(240,240,240,1);
}
.view-entry.dark:hover {
    cursor: pointer;
    background-color: rgba(65,65,65,1);
}

/* alerts */
.alert-item.light:hover {
    cursor:pointer;
    background-color: rgba(240,240,240,1)
}
.alert-item.light:active {
    cursor:pointer;
    background-color: rgba(230,230,230,1)
}
.alert-item.dark:hover {
    cursor:pointer;
    background-color: rgba(55,55,55,1)
}
.alert-item.dark:active {
    cursor:pointer;
    background-color: rgba(75,75,75,1)
}

/* sheets */
.sheet-item.light:hover {
    cursor:pointer;
    background-color: rgba(240,240,240,1)
}
.sheet-item.light:active {
    cursor:pointer;
    background-color: rgba(230,230,230,1)
}
.sheet-item.dark:hover {
    cursor:pointer;
    background-color: rgba(65,65,65,1)
}
.sheet-item.dark:active {
    cursor:pointer;
    background-color: rgba(85,85,85,1)
}

/* form control elements */
.form-control {
    border-radius: 8px !important;
    background: #edebee;
    background-color: #edebee;
    border-color: rgba(175,175,175,0.25) !important
}
.form-control:focus {
    box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}

/* dummy form elements */
.dummy-form {
    border-radius: 8px !important;
    background: #edebee;
    border-color: rgba(175,175,175,0.25) !important
}
.dummy-form:focus {
    box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important
}

/* dummy field elements */
.dummy-field {
    padding: 0.5275rem 0.75rem;
    font-size: 0.7125rem;
    line-height: 1.5;
    font-weight: 500;
    will-change: border-color, box-shadow;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: 8px;
    border: 1px solid rgba(175,175,175,0.25)
}
.dummy-field.light {
    background: rgba(245,245,245,1);
}
.dummy-field.dark {
    background: rgba(85,85,85,1);
}
.dummy-field.light:hover {
    background: rgba(252,252,252,1) !important;
}
.dummy-field.dark:hover {
    background: rgba(100,100,100,0.35) !important;
}
.dummy-field.light.active {
    background: rgba(252,252,252,1) !important;
    border: 1px solid rgba(125,125,125,0.25) !important
    /*box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important*/
}
.dummy-field.dark.active {
    background: rgba(100,100,100,0.35) !important;
    border: 1px solid rgba(175,175,175,0.25) !important
    /*box-shadow: 4px 4px 7px rgba(0,0,0,0.25), -4px -4px 7px rgba(255,255,255,0.025) !important*/
}
.dummy-field.light.disabled {
    background: rgba(0,0,0,0.1) !important;
}
.dummy-field.dark.disabled {
    background: rgba(0,0,0,0.1) !important;
}
.dummy-field-prepend {
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

/* dummy field input elements */
.dummy-field-input:focus {
    border: none !important;
    outline: none !important;
}
.dummy-field-input:-webkit-autofill, .dummy-field-input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px var(--textfield) inset;
    border-top: 1px solid var(--textfield) !important;
    border-bottom: 1px solid var(--textfield) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: var(--text);
}
.dummy-field-input:-webkit-autofill, .dummy-field-input:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px var(--textfield) inset;
    border-top: 1px solid var(--textfield) !important;
    border-bottom: 1px solid var(--textfield) !important;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    -webkit-text-fill-color: var(--text);
}
.dummy-field-select {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -webkit-appearance: none !important;
}
.dummy-field-select:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.dummy-field-select:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

/* custom scrollbars */
.custom-scrollbars::-webkit-scrollbar {
    width: 8px !important;
}
.custom-scrollbars::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.05) !important;
}
.custom-scrollbars.light::-webkit-scrollbar-track {
    background: rgba(252,252,252,1) !important;
}
.custom-scrollbars.dark::-webkit-scrollbar-track {
    background: rgba(55,55,55,1) !important;
}
.custom-scrollbars::-webkit-scrollbar-thumb {
    background-color: rgba(155,155,155,0.25) !important;
    border-radius: 4px !important;
    border: 1.5px solid rgba(155,155,155,0.4) !important;
}

/* custom optional scrollbars */
.custom-optional-scrollbars::-webkit-scrollbar {
    width: 8px;
}
.custom-optional-scrollbars::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.05);
}
.custom-optional-scrollbars.light::-webkit-scrollbar-track {
    background: rgba(252,252,252,1);
}
.custom-optional-scrollbars.dark::-webkit-scrollbar-track {
    background: rgba(55,55,55,1);
}
.custom-optional-scrollbars::-webkit-scrollbar-thumb {
    background-color: rgba(155,155,155,0.25);
    border-radius: 4px;
    border: 1.5px solid rgba(155,155,155,0.4);
}

/* custom sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}
.switch input {
    display:none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider.light {
    background-color: rgba(200,200,200,1);
}
.slider.dark {
    background-color: rgba(85,85,85,1);
}
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
  background-color: #4AA573;
}
input:focus + .slider {
    box-shadow: 0 0 1px #4AA573;
}
input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

/* cursor manipulation flags */
.cursor-grab:hover {
    cursor: grab;
}
.cursor-grabbing:hover {
    cursor: grabbing;
}
.cursor-pointer {
    cursor: pointer !important;
}
.cursor-pointer:hover {
    cursor: pointer !important;
}
.cursor-text-input {
    cursor: text;
}
.image-button {
	transition: transform .2s ease-in-out;
}
.image-button:hover {
	transform: scale(1.1);
	cursor: pointer;
}
.highlight-button {
	transition: all .1s ease-in-out;
}
.highlight-button:hover {
    background-color: rgba(0,0,0,0.05) !important;
	cursor: pointer;
}
.text-button {
	transition: opacity .2s ease-in-out;
}
.text-button:hover {
	opacity: 0.65 !important;
	cursor: pointer;
}

/* form control elements */
.form-control {
    padding: 0.5275rem 0.75rem;
    font-size: 0.7125rem;
    line-height: 1.5;
    font-weight: 500;
    will-change: border-color, box-shadow;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border: 1px solid rgba(150,150,150,0.15);
}
.form-control.light {
    color: rgba(85,85,85,1);
    background-color: rgba(245,245,245,1);
}
.form-control.dark {
    color: white;
    background-color: rgba(85,85,85,1);
}
.form-control:not(textarea) {
    height:35px !important;
}
.form-control.light:hover {
    background-color: rgba(95,95,95,1);
}
.form-control.dark:hover {
    background-color: rgba(100,100,100,1);
}
.form-control:focus {
    /*box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25), 0 0.156rem 0.125rem rgba(108,191,102,0.25);*/
    border: 1px solid rgba(150,150,150,0.5);
}
.form-control.light:focus {
    background-color:white
}
.form-control.dark:focus {
    background-color:rgba(100,100,100,1)
}
.form-control::placeholder {
    color: rgba(150,150,150,1);
}
.form-control.light:disabled, .form-control[readonly] {
    background-color: rgba(225,225,225,1);
}
.form-control.dark:disabled, .form-control[readonly] {
    background-color: rgba(65,65,65,1);
}
.form-control:disabled:hover, .form-control[readonly]:hover {
    border-color: #F85D57;
    cursor: not-allowed;
}
.form-control[readonly]:not(:disabled):focus {
    box-shadow: none;
    border-color: rgba(200,200,200,1);
}

/* dummy form elements */
.dummy-form.light {
    color: rgba(85,85,85,1);
    background-color: rgba(245,245,245,1);
    border: 1px solid rgba(215,215,215,1);
}
.dummy-form.dark {
    color: white;
    background-color: rgba(85,85,85,1);
    border: 1px solid rgba(100,100,100,1);
}
.dummy-form{
    padding: 0.5275rem 0.75rem;
    font-size: 0.7125rem;
    line-height: 1.5;
    font-weight: 500;
    will-change: border-color, box-shadow;
    border-radius: 0.25rem;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06) }
.dummy-form:not(textarea) {
    min-height:35px !important;
}
.dummy-form.light:hover {
    cursor: pointer;
    background-color: white;
}
.dummy-form.dark:hover {
    cursor: pointer;
    background-color: rgba(100,100,100,1);
    border-color: rgba(125,125,125,1);
}
.dummy-form:focus {
    box-shadow: 0 0.313rem 0.719rem rgba(108,191,102,0.25), 0 0.156rem 0.125rem rgba(108,191,102,0.25);
}
.dummy-form.light:focus {
    background-color:white
}
.dummy-form.dark:focus {
    background-color:rgba(100,100,100,1)
}
.dummy-form::placeholder {
    color: rgba(150,150,150,1);
}
.dummy-form.light:disabled, .dummy-form[readonly] {
    background-color: rgba(225,225,225,1);
}
.dummy-form.dark:disabled, .dummy-form[readonly] {
    background-color: rgba(65,65,65,1);
}
.dummy-form:disabled:hover, .dummy-form[readonly]:hover {
    border-color: #F85D57;
    cursor: not-allowed;
}
.dummy-form[readonly]:not(:disabled):focus {
    box-shadow: none;
    border-color: rgba(200,200,200,1);
}

/* buttons */
.btn {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border: 1px solid transparent;
    padding: 0.5625rem 1rem;
    font-size: 0.75rem;
    line-height: 1.125;
    border-radius: 0.25rem;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.btn:hover, .btn.hover {
    cursor: pointer;
}
.btn:focus, .btn.focus {
    box-shadow: none;
}
.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none;
    box-shadow: none;
}
.btn.btn-squared {
    border-radius: 0;
}
.btn.btn-pill {
    border-radius: 50px;
}

/* wysiwyg editor */
.rt-editor-toolbar {
    align-items: center !important;
    background-color: rgba(0,0,0,0) !important;
    border-color: var(--soft_border) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    justify-content: center !important;
    margin-bottom: 0px !important;
    padding: 4px !important;
}
.rt-editor-toolbar div {
  margin-bottom: 0px !important;
}
.rdw-editor-toolbar-hidden {
  display: none !important;
}
.rdw-option-wrapper {
    background-color: rgba(0,0,0,0) !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--text) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 35px !important;
    margin: 0px !important;
    min-width: 35px !important;
    padding: 8px !important;
}
.rdw-option-wrapper img {
  height: 15px !important;
  width: 15px !important;
}
.rdw-option-wrapper:hover {
    background-color: rgba(150,150,150,0.15) !important;
    box-shadow: none !important;
}
.rdw-colorpicker-option {
  padding: 2px !important
}
.rdw-option-active {
    background-color: rgba(150,150,150,0.15) !important;
    box-shadow: none !important;
}
.rdw-dropdown-wrapper  {
    height: 30px !important;
    padding-left: 8px !important;
    border-radius: 8px !important;
    border-color: var(--soft_border) !important;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.rdw-dropdown-wrapper:hover  {
    box-shadow: 0px 2px 8px rgba(0,0,0,0.1) !important
}
.rdw-dropdown-selectedtext {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text) !important;
}
.rdw-dropdown-selectedtext:hover {
    text-decoration: none !important;
}
.rdw-dropdown-optionwrapper {
    margin-top: 6px !important
}
.rdw-colorpicker-option {
    border-radius: 17.5px !important;
    box-shadow: none !important;
    height: 30px !important;
    margin: 2px !important;
    padding: 0px !important;
    width: 30px !important;
}
.rdw-colorpicker-option:hover {
    box-shadow: none !important;
}
.rdw-colorpicker-cube {
    width: 30px !important;
    height: 30px !important;
    border-radius: 17.5px !important;
}
.rdw-colorpicker-cube:hover {
    opacity: 0.75 !important
}

.rdw-colorpicker-modal {
    border: 1px solid var(--soft_border) !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    margin: 0 !important;
    width: 168px !important;
}
.rdw-colorpicker-modal-header {
  display: none !important;
}
.rdw-colorpicker-modal-style-label {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--text) !important;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: none !important;
  color: #4A90E2 !important;
}
.rdw-emoji-modal {
    border: 1px solid var(--soft_border) !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    justify-content: center !important;
    margin: 0 !important;
    width: 215px !important;
}
.rdw-emoji-icon {
  margin: 6px !important
}
.rdw-dropdown-optionwrapper {
    padding: 8px !important;
    margin-top: 6px !important;
    border-radius: 10px !important;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1) !important;
    border: 1px solid var(--soft_border) !important;
}
.rdw-dropdown-selectedtext {
    width: 50px !important
}
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose {
    margin-right: 4px
}
.rdw-dropdownoption-default.rdw-fontsize-option {
    border-radius: 6px !important
}
.rt-editor-container {
    overflow-y: scroll !important;
    padding: 0px 14px 0px 14px !important;
}
.rt-editor-container.light {
    background-color: rgb(252,252,252) !important;
}
.rt-editor-container.dark {
    background-color: rgba(150,150,150,0.15) !important;
}
.rt-editor-container.read_only {
  border: none !important;
  border-radius: none !important;
}
.rt-editor-container.editable {
    border-bottom: 1px solid var(--soft_border) !important;
    border-left: 1px solid var(--soft_border) !important;
    border-right: 1px solid var(--soft_border) !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    max-height: calc(70vh) !important;
    min-height: 500px !important;
}
.rt-button.light {
    background-color: rgb(252,252,252) !important;
}
.rt-button.dark {
    background-color: rgba(75,75,75,1) !important;
}
.rt-modal {
  border-radius: 15px !important;
  height: auto !important;
  max-height: 400px !important;
  overflow-y: scroll;
}
.rt-modal.light {
    background-color: rgb(252,252,252) !important;
}
.rt-modal.dark {
    background-color: rgba(75,75,75,1) !important;
}
.rdw-colorpicker-modal-options {
    margin: 0px !important;
    overflow: hidden !important;
}
@media (prefers-color-scheme: dark) {
    .rdw-option-wrapper {
        background-color: rgba(75,75,75,1) !important;
    }
    .rdw-dropdown-carettoopen {
        border-top-color: white !important;
    }
    .rdw-dropdown-carettoclose {
        border-bottom-color: white !important;
    }
}
@media (max-width:480px)  {
  .rt-editor-container {
    font-size: 16px;
  }
  .rdw-colorpicker-cube {
    min-height: 25px !important;
    min-width: 30px !important;
  }
  .rdw-editor-toolbar {
    padding: 12px !important;
  }
  .rdw-emoji-icon {
    height: 30px !important;
    font-size: 32px !important;
    width: 30px !important;
  }
  .rdw-option-wrapper {
    border: 1px solid var(--grey) !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    height: 35px !important;
    margin-left: 4px !important; 
    margin-right: 4px !important; 
    margin-top: 8px !important; 
    min-width: 30px !important;
    padding: 8px !important;
  }
  .rdw-option-wrapper.rdw-colorpicker-option {
    border: none !important;
    height: 35px !important;
    min-width: 35px !important;
  }
  .rdw-option-wrapper img {
    height: 35px !important;
    width: 35px !important;
  }
  .rt-modal {
    max-height: 500px !important;
  }
  .rt-modal::-webkit-scrollbar {
    width: 0px !important;
  }
  .rt-modal::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0) !important;
  }
  .rdw-colorpicker-modal {
    max-height: 500px !important;
  }
}

/* custom select */
.custom-select {
    height: calc(35px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    font-weight: 500;
    font-size: 12px;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: 8px !important;
    background: #edebee;
    border-color: rgba(175,175,175,0.25) !important
}
.custom-select.light {
    color: rgba(85,85,85,1);
    background: rgba(245,245,245,1) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
}
.custom-select.dark {
    color: white;
    background: rgba(55,55,55,1) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
}
.custom-select:focus {
    box-shadow: none;
}
.custom-select.light:focus  {
    background: rgba(252,252,252,1) !important;
    /*box-shadow: 4px 4px 7px rgba(174,174,174,0.25), -4px -4px 7px #ffffff !important*/
}
.custom-select.dark:focus  {
    background: rgba(100,100,100,0.35) !important;
    /*box-shadow: 4px 4px 7px rgba(0,0,0,0.25), -4px -4px 7px rgba(255,255,255,0.025) !important*/
}
.custom-select.light:hover:not(:focus):not(:disabled) {
    cursor: pointer;
    background-color: white;
}
.custom-select.dark:hover:not(:focus):not(:disabled) {
    cursor: pointer;
    background-color: rgba(65,65,65,1);
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
}
.custom-select:disabled, .custom-select[readonly] {
    background-color: rgba(225,225,225,1);
}
.custom-select:disabled:hover, .custom-select[readonly]:hover {
    border-color: #F85D57;
    cursor: not-allowed;
}

/* custom file input */
.custom-file {
    height: calc(2.0625rem + 2px);
    font-size: 0.7125rem;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.custom-file-input.light {
    background-color:rgba(245,245,245,1);
}
.custom-file-input.dark {
    background-color:rgba(100,100,100,1);
}
.custom-file-input {
    min-width: 14rem;
    height: calc(2.0625rem + 2px);
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}
.custom-file-input.light:focus ~ .custom-file-label.light {
    color: rgba(85,85,85,1);
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}
.custom-file-input.dark:focus ~ .custom-file-label.dark {
    border-color: rgba(125,125,125,1);
    color: white;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
}
.custom-file-input.light:focus ~ .custom-file-label.light::after {
    color: rgba(85,85,85,1);
    background-color: white
}
.custom-file-input.dark:focus ~ .custom-file-label.dark::after {
    border-color: rgba(125,125,125,1);
    color: white;
    background-color: rgba(100,100,100,1);
}
.custom-file-input.dark:focus ~ .custom-file-label.dark:hover {
    border-color: rgba(125,125,125,1);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}
.custom-file-input:not(:disabled):hover {
    cursor: pointer;
}
.custom-file-input.light:not(:disabled):hover ~ .custom-file-label.light,
.custom-file-input.light:not(:disabled):hover ~ .custom-file-label.light:before {
    background-color: white;
}
.custom-file-input.dark:not(:disabled):hover ~ .custom-file-label.dark,
.custom-file-input.dark:not(:disabled):hover ~ .custom-file-label.dark:before {
    border-color: rgba(125,125,125,1);
    background-color: rgba(100,100,100,1);
}
.custom-file-input.light:disabled + .custom-file-label {
    color: rgba(65,65,65,1);
    background-color: rgba(225,225,225,1);
}
.custom-file-input.dark:disabled + .custom-file-label {
    color: rgba(225,225,225,1);
    background-color: rgba(55,55,55,1);
}
.custom-file-label.light {
    color: rgba(85,85,85,1);
    background-color: rgba(245,245,245,1);
}
.custom-file-label.dark {
    color: white;
    background-color: rgba(85,85,85,1);
}
.custom-file-label {
    border: 1px solid rgba(175,175,175,0.25) !important;
    height: calc(2.0625rem + 2px);
    padding: 0.5rem 1rem;
    line-height: 1.5;
    font-weight: 300;
    box-shadow: none;
    transition: all 500ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: 8px !important;
    white-space: nowrap;
}
.custom-file-label.light::after {
    color: rgba(85,85,85,1);
    border-left: 1px solid rgba(215,215,215,1);
    background-color: rgba(245,245,245,1);
}
.custom-file-label.dark::after {
    color: white;
    border-left: 1px solid rgba(100,100,100,1);
    background-color: rgba(85,85,85,1);
}
.custom-file-label::after {
    padding: 0.5rem 1rem;
    height: calc(calc(2.0625rem + 2px) - 1px * 2);
    line-height: 1.5;
    border-radius: 0 8px 8px 0;
}

/* navbar components */
.main-navbar {
  height: 3.75rem;
}
.main-navbar > * {
    display: flex;
}
.main-navbar .nav-link {
    min-width: 3.75rem;
}
.main-navbar .notifications {
    position: relative;
}
@media (max-width: 767.98px) {
    .main-navbar .notifications {
        position: static;
    }
    .main-navbar .notifications .dropdown-menu {
        min-width: 100% !important;
        border-left: none;
    }
}
.main-navbar .notifications .badge {
    position: absolute;
    padding: 0.25rem 0.375rem;
    font-size: 0.5rem;
    left: 50%;
    top: 50%;
}
.main-navbar .notifications .dropdown-menu {
    padding: 0;
    min-width: 25rem;
    border-right: 0;
    left: auto;
}
@media (max-width: 767.98px) {
    .main-navbar .notifications .dropdown-menu {
        left: 0;
        right: 0;
    }
}
.main-navbar .notifications .dropdown-menu .dropdown-item {
    white-space: normal;
    display: flex;
    flex-flow: row;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid rgba(45,45,45,1);
}
.main-navbar .notifications .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
}
.main-navbar .notifications .dropdown-menu .notification__icon-wrapper {
    display: flex;
    padding: 0 5px;
}
.main-navbar .notifications .dropdown-menu .notification__icon {
    box-shadow: 0 0 0 1px rgba(0,0,0,0.25), inset 0 0 3px rgba(0, 0, 0, 0.2);
    width: 2.1875rem;
    height: 2.1875rem;
    line-height: 0;
    display: block;
    text-align: center;
    margin: auto;
    border-radius: 50%;
}
.main-navbar .notifications .dropdown-menu .notification__icon i {
    color: #818EA3;
    line-height: 2.0625rem;
    font-size: 1.0625rem;
    margin: 0;
}
.main-navbar .notifications .dropdown-menu .notification__content {
    padding: 0 0.625rem;
}
.main-navbar .notifications .dropdown-menu .notification__content p {
    margin: 0;
    line-height: 1.5;
    font-size: 0.75rem;
}
.main-navbar .notifications .dropdown-menu .notification__category {
    font-size: 0.5625rem;
    color: #818EA3;
    letter-spacing: 0.0938rem;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500;
}
.main-navbar .notifications .dropdown-menu .notification__all {
    display: block;
    font-weight: 500;
    font-size: 11px;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}
.main-navbar .main-navbar__icon-actions .nav-link {
    border:none;
}
.main-navbar .user-avatar {
    max-width: 2.5rem;
}
.main-navbar .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
}
.main-navbar .nav-link-icon i {
    line-height: 2.5rem;
}
.main-content > .main-navbar {
    box-shadow: none
}
.main-navbar.light {
    background-color: white;
}
.main-navbar.dark {
    background-color: rgba(45,45,45,1);
}

/* sidebar components */
.main-sidebar.light {
    background-color: white;
}
.main-sidebar.dark {
    background-color: rgba(25,25,25,1);
}
.main-sidebar {
    top: 0;
    position: fixed;
    height: calc(100vh);
    z-index: 1070;
    will-change: transform;
    transition: transform 200ms ease-in-out;
    box-shadow: none
}
@media (max-width: 767.98px) {
    .main-sidebar {
        box-shadow: none;
    }
}
.main-sidebar.open {
    transform: translateX(0);
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.5275rem 2.1875rem rgba(165, 182, 201, 0.1);
}
.main-sidebar .toggle-sidebar {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1.25rem;
    font-size: 1.25rem;
    border: 0px solid rgba(45,45,45,1);
}
.main-sidebar .toggle-sidebar:hover {
    cursor: pointer;
}
.main-sidebar .navbar-brand {
    overflow: hidden;
    height: 3.75rem;
    font-size: 1rem;
}
@media (max-width: 991.98px) {
    .main-sidebar .navbar-brand {
        font-size: 90%;
    }
}
.main-sidebar .nav-wrapper {
    overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom: 200px
}
.main-sidebar .nav .nav-item,
.main-sidebar .nav .nav-link {
    white-space: nowrap;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    will-change: background-color, box-shadow, color;
    transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease;
    font-size: 0.85rem;
}
.main-sidebar .nav .nav-item .nav-link {
    font-weight: 400;
    padding: 0.6375rem 1.5625rem;
}
.main-sidebar .nav .nav-item.light .nav-link {
    color: rgba(85,85,85,1);
    border-bottom: 1px solid rgba(255,255,255,0.75);
}
.main-sidebar .nav .nav-item.dark .nav-link {
    color: white;
    border-bottom: 1px solid rgba(85,85,85,0.25);
}
.main-sidebar .nav .nav-item .nav-link i {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: rgba(85,85,85,1);
    transition: color 200ms ease;
    margin-right: 0.375rem;
}
.main-sidebar .nav .nav-item .nav-link i.light {
    color: rgba(85,85,85,1);
}
.main-sidebar .nav .nav-item .nav-link i.material-icons {
    font-size: 1.125rem;
    top: -1px;
}
.main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item:hover, .main-sidebar .nav .nav-item .nav-link:hover {
    box-shadow: inset 0.1875rem 0 0 rgba(0,0,0,0);
    color: #4D9D47;
}
.main-sidebar .nav .nav-item.active i, .main-sidebar .nav .nav-item .nav-link.active i, .main-sidebar .nav .nav-item:hover i, .main-sidebar .nav .nav-item .nav-link:hover i {
    color: #4D9D47;
}
.main-sidebar .dropdown-menu {
    position: static !important;
    transform: translate(0) !important;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    border: none;
    padding: 0;
    box-shadow: inset 0 -0.1875rem 0.1875rem rgba(61, 81, 112, 0.08);
}
.main-sidebar .dropdown-menu .dropdown-item.light {
    color: rgba(85,85,85,1);
}
.main-sidebar .dropdown-menu .dropdown-item.dark {
    color: white;
}
.main-sidebar .dropdown-menu .dropdown-item {
    padding: 0.75rem 2.3rem;
    font-size: 0.7125rem;
    font-weight: 500;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .main-sidebar .dropdown-menu .dropdown-item {
        font-weight: 300;
    }
}
.main-sidebar .dropdown-menu .dropdown-item:hover, .main-sidebar .dropdown-menu .dropdown-item.active {
    background-color: rgba(0,0,0,0);
}
.main-sidebar .dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 1px solid rgba(245,245,245,0.1);
}
.main-sidebar .dropdown-menu .dropdown-divider {
    margin: 0;
}
.main-sidebar .dropdown-toggle {
    position: relative;
}
.main-sidebar .dropdown-toggle::after {
    border: none;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
    background-position: center center;
    width: 0.875rem;
    height: 0.5625rem;
    position: absolute;
    top: 50%;
    right: 0.625rem;
    transform: translateY(-50%);
    opacity: .1;
    transition: transform 250ms ease-in-out;
    will-change: transform;
}
.main-sidebar .dropdown.show .dropdown-toggle::after {
    transform: translateY(-50%) rotateZ(180deg);
}
@media (max-width: 767.98px) {
    .main-sidebar__search {
        box-sizing: border-box;
        border-right: 0 !important;
        padding: 0.625rem 0;
        border-bottom: 1px solid #e1e5eb;
    }
    .main-sidebar__search .input-group {
        margin: 0 !important;
    }
}
